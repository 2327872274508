import { AuditLogApi, CasesApi, IcdRulesApi, MacrosApi, SpecimensApi } from "@/services";
import { mergeSpecimensWithMacros } from "./mergeSpecimensWithMacros";
import { uniqBy } from "lodash";
import moment from "moment";
import store from "@/store";

import { getSpecimenGCode } from "./getSpecimenGCode";
import { handleMissingCptPairs } from "./handleMissingCptPairs";
import { AuditLogItems } from "./enums";
import { createLogItem } from "./helpers";

export default async function (prefix, caseDetails) {
  if (!store.getters.permissions?.CaseFinalizeSlidePrep) {
    window.alert("User does not have permission to finalize cases.");
    return;
  }
  const { site, siteRequired, resultsMacroId, pathologistId, protocolId } = prefix;
  if (Array.isArray(caseDetails.pathologists)) {
    if (pathologistId) {
      const currentPathologistIndex = caseDetails.pathologists.findIndex(
        e => e.id === pathologistId
      );
      caseDetails.pathologists = caseDetails.pathologists.map(e => ({ ...e, isPrimary: false }));
      if (currentPathologistIndex > -1) {
        caseDetails.pathologists[currentPathologistIndex].isPrimary = true;
      } else {
        caseDetails.pathologists = uniqBy(
          [...caseDetails.pathologists, { id: pathologistId, isPrimary: true }],
          "id"
        );
      }
    } else if (!caseDetails.pathologists.find(e => e.isPrimary)) {
      throw new Error("No primary pathologist found.");
    }
  } else {
    caseDetails.pathologists = [{ id: pathologistId, isPrimary: true }];
  }
  if (resultsMacroId) {
    const macro = await MacrosApi.getMacroDetails(resultsMacroId);
    caseDetails.specimens = await mergeSpecimensWithMacros(
      caseDetails.specimens,
      [macro],
      `.${macro.macroName}`,
      null,
      true
    );
    for (const specimen of caseDetails.specimens) {
      if (protocolId && !specimen.protocolId) {
        specimen.protocolId = protocolId;
      }
      if (!siteRequired && !specimen.site) {
        specimen.site = site;
      }
      specimen.pathologists = caseDetails.pathologists;
      specimen.contact = caseDetails.contacts;
      if (!specimen?.isICDModified) {
        const icdEngineCalls = await Promise.all(
          macro.icdCodes.map(async code => {
            if (
              moment(code.expiryOn).isBefore(new Date()) ||
              moment(code.effectiveOn).isAfter(new Date())
            ) {
              window.notify(
                `Icd code outside of effective range found ${code.displayCode}.`,
                "warning"
              );
            }
            const response = await IcdRulesApi.findBillableCode({
              caseReceivedOn: caseDetails.receivedOn,
              searchFor: code.displayCode.replace(/\.([0-9]+)/gi, ""),
              specimenDiagnosis: specimen.diagnosis,
              specimenSite: specimen.site ?? ""
            });
            return [response, code];
          })
        );

        icdEngineCalls.forEach(([response, code]) => {
          const nonBillableCodes = [];
          let newCodes = macro.icdCodes;
          if ([3, 4, 6, 3, 5, 7, 8].includes(response.detail) && !code.isBillable) {
            nonBillableCodes.push(code.displayCode);
          } else if ([2].includes(response.detail)) {
            const { replacementIcdCode } = response;

            newCodes = newCodes.map(icd => {
              if (icd.id === code.id && replacementIcdCode !== icd.id) {
                return replacementIcdCode;
              }
              return icd;
            });
          }

          specimen.icdCodes = uniqBy([...(specimen?.icdCodes || []), ...newCodes], "id");
        });
      }
    }
  }
  try {
    caseDetails.specimens = await SpecimensApi.updateSpecimen(caseDetails.specimens);
    handleMissingCptPairs(caseDetails.specimens);
  } catch (error) {
    if (error?.response?.data && /g\s?code/i.test(error.response.data)) {
      const gCode = await getSpecimenGCode(error.response.data, caseDetails.specimens);
      const specimensWithGcode = caseDetails.specimens.map((specimen, idx) => {
        if (idx === 0) {
          specimen.cptCodes = [{ id: gCode }, ...(specimen.cptCodes || [])];
        }
        return specimen;
      });
      caseDetails.specimens = await SpecimensApi.updateSpecimen(specimensWithGcode);
      handleMissingCptPairs(caseDetails.specimens);
    } else {
      throw error;
    }
  }
  caseDetails = await CasesApi.updateCase(caseDetails);
  const labLocationId = store.state.currentLabLocation || store.state.availableLabLocations[0].id;

  await CasesApi.signCase(caseDetails.caseId, labLocationId);
  const logItem = createLogItem(caseDetails, AuditLogItems.ChangeAccession);
  logItem.comments = `Finalized specimens for case ${caseDetails.caseNumber}.`;
  AuditLogApi.insertLogMessage(logItem);
  return caseDetails;
}
